// Document ready
document.addEventListener("DOMContentLoaded", function() {
	baselineGrid();
});

//Baseline Grid
function baselineGrid() {
	var baselineGridToggle = document.querySelector(".baseline-toggle");

	if (baselineGridToggle) {
		baselineGridToggle.addEventListener("click", function() {
			document.querySelector("html").classList.toggle("js-baseline");
		});
	}
}
