// Document ready
document.addEventListener("DOMContentLoaded", function() {
	preventDefaultUtil();
});

//Prevent Default
function preventDefaultUtil() {
	var preventDefaultItems = document.querySelectorAll(".js-prevent-default"),
		i;

	if (preventDefaultItems[0] != undefined) {
		for (i = 0; i < preventDefaultItems.length; ++i) {
			preventDefaultItems[i].addEventListener("click", function(e) {
				e.preventDefault();
			});
		}
	}
}
