// Document ready
document.addEventListener("DOMContentLoaded", function() {
	LazyLoadingInit();
});

// LazyLoading

function LazyLoadingInit() {
	var myLazyLoad = new LazyLoad({
		elements_selector: "[data-src], [data-bg]",
		threshold: 400,
		callback_set: function(el) {
			objectFitImages(el);
		}
	});
}
