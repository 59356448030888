//Document ready
$(function () {
	if ($('[data-aos]').length) {
		AOS.init({
			delay: 300,
			duration: 1000
		});
	}
	$(".product-action > span").on("click", function() {
	  var $button = $(this);
	  var oldValue = $button.parent().find("input").val();

	  if ($button.text() == "+") {
		  var newVal = parseFloat(oldValue) + 1;
		} else {
	   // Don't allow decrementing below 1
	    if (oldValue > 1) {
	      var newVal = parseFloat(oldValue) - 1;
	    } else {
	      newVal = 1;
	    }
	  }
	  $button.parent().find("input").val(newVal);
	});
});
