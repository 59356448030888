// Document ready
document.addEventListener("DOMContentLoaded", function () {

	videoIframeWrap();
});

//Video iframe wrap
function videoIframeWrap() {
	var contentIframes = document.querySelectorAll(".entry-content iframe"),
		i;

	for (i = 0; i < contentIframes.length; ++i) {
		var itemSrc = contentIframes[i].getAttribute("src");

		if (itemSrc.indexOf("youtube") != -1) {
			contentIframes[i].classList.add("embed-responsive-item");
			contentIframes[i].removeAttribute("height");
			contentIframes[i].removeAttribute("width");

			var iframeWrap = document.createElement("div");
			iframeWrap.classList.add("embed-responsive", "embed-responsive-16by9");

			contentIframes[i].parentNode.insertBefore(iframeWrap, contentIframes[i]);

			iframeWrap.appendChild(contentIframes[i]);
		}
	}
}
